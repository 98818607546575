.BreadcrumComponent .breadcrumb-item.active {
    color: #000;
    font-weight: 600;
    opacity: 1;
}


.BreadcrumComponent .breadcrumb-item
  + .breadcrumb-item::before {
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, ">");
}