.Product_Details_Div .color_cart_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
}

.Product_Details_Div .color_cart_holder .color_option_holder .circleClassName {
  font-size: 10px;
  margin-right: 8px;
}

.Product_Details_Div .color_cart_holder .Cart_Holder .CartClassName {
  color: #495057;
  font-size: 18px;
}

.Product_Details_Div .price_holder {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.Product_Details_Div .text-holder {
  margin-bottom: 10px;
}


.Product_Details_Div .text-holder  p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}





@media (min-width: 576px) and (max-width: 767px) {

  .Product_Details_Div .color_cart_holder .Cart_Holder .CartClassName {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .Product_Details_Div .color_cart_holder .Cart_Holder .CartClassName {
    font-size: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {

  .Product_Details_Div .color_cart_holder .Cart_Holder .CartClassName {
    font-size: 13px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {

  .Product_Details_Div .color_cart_holder .Cart_Holder .CartClassName {
    font-size: 13px;
  }
}