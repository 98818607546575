.succs-modl .modals-top{
    position: relative;
}

.succs-modl .loginlogomain {
    position: absolute;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 3px solid #FF8C28;
    bottom: -38px;
    background-color: #fff;
    left: calc( 50% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.succs-modl .loginlogomain .loglogo{
    width: 60px;
    height: 60px;
}

.succs-modl .modal-heading.modal-header {
    background-color: #FF8C28;
    height: 47px;
}

.succs-modl  .modalssbody.modal-body {
    padding-top: 53px;
    text-align: center;
    padding-bottom: 20px;
}