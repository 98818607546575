.frequently-bought-together-one {
    margin-top: 3%;
    /* margin-bottom: 10%; */
}

section.frequently-bought-together-two {
    margin-bottom: 10%;
}

.frequently-bought-together-one .hediang-holder h2 {
    font-size: 20px;
    font-weight: 600;
}

.frequently-bought-together-one .main-section .seller-img {
    border-radius: 10px;
    width: 100%;
    height: 195px;
}

.frequently-bought-together-one .main-section h4 {
    font-size: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
}

.frequently-bought-together-one .main-section p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0%;
}

.frequently-bought-together-one .main-section span {
    color: #484848;
    font-size: 12px;
}

.frequently-bought-together-one .silder-btn {
    position: relative;
}

.frequently-bought-together-one .silder-btn .back-btn {
    /* content: url('../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/left.png'); */
    width: 24px;
    height: 24px;
    /* margin: 10px; */
    cursor: pointer;
    position: absolute;
    bottom: 205px;
    z-index: 999;
    left: 0px;

}

.frequently-bought-together-one .silder-btn .next-btn {
    /* content: url('../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/right.png'); */
    width: 24px;
    height: 24px;
    /* margin: 10px; */
    cursor: pointer;
    position: absolute;
    bottom: 205px;
    z-index: 999;
    right: 0px;
}

.frequently-bought-together-one .swiper {
    padding: 7px 9px;
}

.frequently-bought-together-one .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    display: none;
}

.frequently-bought-together-one .click-btn {
font-size: 13px;
padding: 4px 9px;
}


@media(min-width:0px) and (max-width:486px) {
    section.frequently-bought-together-two {
        margin-bottom: 30%;
    }

    .frequently-bought-together-one .click-btn {
        font-size: 11px;
        padding: 5px 3px;
    }
}

@media(min-width:486px) and (max-width:767px) {
    section.frequently-bought-together-two {
        margin-bottom: 20%;
    }

    .frequently-bought-together-one .click-btn {
        font-size: 11px;
        padding: 5px 3px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    section.frequently-bought-together-two {
        margin-bottom: 18%;
    }

    .frequently-bought-together-one .click-btn {
        font-size: 12px;
        padding: 3px 7px;
    }
}