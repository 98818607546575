/* .Trending-Products Css Started */


.Trending-Products .Swiper-Product-one .Cards {
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
}

.Trending-Products .Swiper-Product-one .swiper-wrapper {
  padding-bottom: 3%;
  padding-top: 3%;
}

.Trending-Products .Swiper-Product-Two .Products-cards-two {
  width: 100%;
  height: 171px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  border: 1px solid white;
}
.Trending-Products .Swiper-Product-Two .Products-cards-two:hover {
  border: 1px solid orange;
}
.Trending-Products .Swiper-Product-Two .Products-cards-two .swiper {
  padding: 13px 6px;
}

.Trending-Products .Main .image img.main-img {
  width: 177px;
  height: 129px;
  border-radius: 10px;
}

.Trending-Products .Main {
  display: flex;
}

.Trending-Products .Main .content .view-details button.View {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 3px;
}

.Trending-Products .Main .content .view-details {
  transform: translate(-50%, 10px);
  left: 35%;
  position: absolute;
  bottom: 0px;
}

.Trending-Products .Main .content {
  padding: 18px 10px;
  position: relative;
}

.Trending-Products .Products-cards-two {
  padding: 21px 16px;
  width: 100%;
  height: 171px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
}

.Trending-Products .Swiper-Product-Two .swiper {
  padding-right: 1%;
  padding-left: 1%;
  padding-top: 3%;
  padding-bottom: 5%;
}

.Trending-Products .mySwipertwo .swiper-pagination-bullet-active {
  background: #ff8c28;
  border-radius: 5px;
}

.Trending-Products .Swiper-Product-one .swiper {
  padding: 0px 16px;
}

.Trending-Products .ProductCards2 .btn-style511 {
  --btn-color: transparent;
  --btn-hover-color: #dd6395;
  position: relative;
  border: none;
  background-color: var(--btn-color);
  color: #484848;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.25s ease;
}

.Trending-Products .ProductCards2 .btn-style511 span {
  position: relative;
  z-index: 2;
}

.Trending-Products .ProductCards2 .btn-style511::before {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #1d44ad;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}

.Trending-Products .ProductCards2 .btn-style511::after {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #1d44ad;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}


.Trending-Products .ProductCards2 .btn-style511::before {
  top: 0;
  transform: translateX(-100%);
}

.Trending-Products .btn-style511::after {
  bottom: 0;
  transform: translateX(100%);
}

.Trending-Products .Products-cards-two:hover .btn-style511 {
  color: #fff;
}

.Trending-Products .Products-cards-two:hover .btn-style511::before,
.btn-style511:hover::after {
  transform: translateX(0);
}

.Trending-Products .Products-cards-two:hover .btn-style511::after {
  transform: translateX(0);
}

.Trending-Products .ProductCards2 .click-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.35s;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background: white;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 13px;
  color: black;
  font-weight: 500;
  padding: 5px 16px;
}

.Trending-Products .ProductCards2 .click-btn span {
  position: relative;
  z-index: 2;
}

.Trending-Products .ProductCards2 .click-btn::before {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #1d44ad;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}

.Trending-Products .ProductCards2 .click-btn::after {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #1d44ad;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}

.Trending-Products .ProductCards2 .click-btn::before {
  top: 0;
  transform: translateX(-100%);
}

.Trending-Products .ProductCards2 .click-btn::after {
  bottom: 0;
  transform: translateX(100%);
}

.Trending-Products .ProductCards2:hover .click-btn {
  color: #fff;
}

.Trending-Products .ProductCards2:hover .click-btn::before {
  transform: translateX(0);
}

.Trending-Products .ProductCards2:hover .click-btn::after {
  transform: translateX(0);
}

/* .Trending-Products Css End */



/* *************************Media Query started******************** */

@media (min-width: 0px) and (max-width: 485px) {
  .Trending-Products .Swiper-Product-Two .Products-cards-two {
    height: 244px;
    padding: 13px 14px;
  }

  .Trending-Products .Main {
    display: block;
  }

  .Trending-Products .Main .content .view-details {
    bottom: -20px;
    left: 50%;
}
  .Trending-Products .Swiper-Product-one .swiper {
    padding-bottom: 6%;
  }

  .Trending-Products .Main .image img.main-img {
    width: 100%;

  }

  .Trending-Products .Swiper-Product-Two .swiper {

    padding-bottom: 8%;
  }

  .Trending-Products .Main .content {
    text-align: center;
  }

  .ProductCards2 {
    text-align: -webkit-center;
  }
}
@media (min-width: 486px) and (max-width: 767px) {
  .Trending-Products .Main .content .view-details {
    transform: translate(0%, 0px);
    left: 8%;

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Trending-Products .ProductCards2 .click-btn {
    font-size: 10px;
    padding: 5px 7px;
  }
  .Trending-Products .Main .content .view-details {
    transform: translate(-50%, 10px);
    left: 38%;
  
}
}
