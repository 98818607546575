.Incre_Decre_btn {
    background: #E1E1E1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    padding: 0px 5px;
    border-radius: 3px;
}



.counter_btn {
    background: #fff;
    border-radius: 3px;
    border: none;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.scale-up-hor-right {
	-webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-hor-right {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }
  @keyframes scale-up-hor-right {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }
  