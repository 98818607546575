@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: "Roboto", "sans-serif" !important;
}


p {
    margin-bottom: 0px !important;
}

a {
    text-decoration: none !important;
}


.form-select:focus {
    box-shadow: none !important;
}

.breadcrumb-item a {
    font-size: 15px;
    color: #333333;
    text-decoration: none;
}

.heading-Outer-five{
    color: #333333;
    font-size: 40px;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}
.outer-five-sub-heading{
    font-size: 25px;
    color: #FFFFFF;
    font-family: "Poppins" !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}
.text-outer-five{
    font-size: 14px;
    color: #000000;
    font-family: "Poppins" !important;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}
@media (min-width:0px) and (max-width:485px) {
    .heading-Outer-five{
        font-size: 22px;
    }
    .outer-five-sub-heading{
        font-size: 14px;
    }
    .text-outer-five{
        font-size: 12px;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .heading-Outer-five{
        font-size: 24px;
    }
    .outer-five-sub-heading{
        font-size: 16px;
    }
    .text-outer-five{
        font-size: 12px;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .heading-Outer-five{
        font-size: 26px;
    }
    .outer-five-sub-heading{
        font-size: 18px;
    }
    .text-outer-five{
        font-size: 12px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .heading-Outer-five{
        font-size: 28px;
    }
    .text-outer-five{
        font-size: 12px;
    }
    .outer-five-sub-heading{
        font-size: 18px;
    }
}