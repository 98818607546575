.contact-us {
  margin-bottom: 12%;
}


.contact-us .map-section .gmap_iframe {
  width: 100% !important;
  height: 312px !important;
}

.contact-us .heading-holder h1 {
  font-size: 23px;
  font-weight: 600;
}

.contact-us .heading-holder p {
  font-size: 20px;
}

.contact-us .heading-holder h2 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}

.contact-us .heading-holder h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 14px;
}

.contact-us .heading-holder span {
  font-size: 15px;
}

.contact-us .form-start .form-control {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #7B7B7B;
  font-size: 14px;
  /* border: none; */
}

.contact-us .form-start .form-control:focus {
  box-shadow: none;
}

.contact-us .text-holder span {
  font-size: 14px;
}

.contact-us .social-icon .sociallogo {
  width: 20px;
  height: 20px;

}

/* .contact-us .form-start .btn-submit {
  background: #707070;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 15px;
  color: #FFF;
  font-weight: 500;
  padding: 7px 30px;
  position: relative;
  overflow: hidden;
  font-variant: all-petite-caps;
  transition: 0.3s ease-in-out;
}

.contact-us .form-start .btn-submit:before,
.contact-us .form-start .btn-submit:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: #000;
  transition: 0.3s ease-in-out;
}

.contact-us .form-start .btn-submit:before {
  top: 0;
  right: 100%;

}

.contact-us .form-start .btn-submit:after {
  bottom: 0;
  left: 100%;

}

.contact-us .form-start .btn-submit:hover:before,
.contact-us .form-start .btn-submit:hover:after {
  right: 0;
  left: 0;
}

.contact-us .form-start .btn-submit:hover {
  color: #FFF !important;

}

.contact-us .form-start .btn-submit {
  position: relative;
  z-index: 2;
}

.button5 {
  position: relative;
}

.button5:before,
.button5:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: #000;
}

.button5:before {
  right: 0%;
  top: 0;
}

.button5:after {
  left: 0%;
  bottom: 0;
}

.button5:hover:before {
  right: 0;
}

.button5:hover:after {
  left: 0;
} */

.contact-us .btn-style511 {
  --btn-color: transparent;
  --btn-hover-color: #dd6395;
  position: relative;
  border: none;
  background-color: var(--btn-color);
  color: #484848;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.25s ease;
}

.contact-us .btn-style511 span {
  position: relative;
  z-index: 2;
}

.contact-us .btn-style511::before,
.btn-style511::after {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: var(--btn-hover-color);
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}


.contact-us .btn-style511::before {
  top: 0;
  transform: translateX(-100%);
}

.contact-us .btn-style511::after {
  bottom: 0;
  transform: translateX(100%);
}

.contact-us .btn-style511:hover {
  color: #fff;
}

.contact-us .btn-style511:hover::before,
.btn-style511:hover::after {
  transform: translateX(0);
}

.contact-us .click-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.35s;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background: #707070;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 15px;
  color: #FFF;
  font-weight: 500;
  padding: 7px 30px;
}

.contact-us .click-btn span {
  position: relative;
  z-index: 2;
}

.contact-us .click-btn::before {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #000;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}

.contact-us .click-btn::after {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #000;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}

.contact-us .click-btn::before {
  top: 0;
  transform: translateX(-100%);
}

.contact-us .click-btn::after {
  bottom: 0;
  transform: translateX(100%);
}

.contact-us .click-btn:hover {
  color: #fff;
}

.contact-us .click-btn:hover::before,
.click-btn:hover::after {
  transform: translateX(0);
}

@media(min-width:567px) and (max-width:767px) {
  .contact-us {
    margin-bottom: 25%;
  }

  .contact-us .heading-holder h1 {
    font-size: 18px;
  }

  .contact-us .heading-holder p {
    font-size: 15px;
  }
}

@media(min-width:0px) and (max-width:567px) {
  .contact-us {
    margin-bottom: 35%;
  }

  .contact-us .heading-holder h1 {
    font-size: 20px;
  }

  .contact-us .heading-holder p {
    font-size: 15px;
  }
}

@media(min-width:768px) and (max-width:1200px) {
  .contact-us {
    margin-bottom: 20%;
  }
}