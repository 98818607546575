.privacy-policy {
    margin-top: 5%;
    margin-bottom: 15%;
}

.privacy-policy .heading-holder h1 {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
}

.text-holder p {
    font-size: 14px;
}

@media(min-width:0px) and (max-width:486px) {
    .privacy-policy {
        margin-bottom: 35%;
    }
    .privacy-policy .heading-holder h1 {
        font-size: 18px;
    }
}

@media(min-width:486px) and (max-width:767px) {
    .privacy-policy .heading-holder h1 {
        font-size: 18px;
    }
    .privacy-policy {
        margin-bottom: 28%;
    }
   
}

@media(min-width:767px) and (max-width:992px) {
    .privacy-policy {
        margin-bottom: 20%;
    }
}