/* Category Css Started */

.Category .product-one .product-image img.products {
  width: 100%;
  height: 401px;
  border-radius: 10px;
  transition: transform 0.5s ease-in-out;
  object-fit: cover;
  object-position: top;
}

.Category .product-one .product-image {
  overflow: hidden;
  border-radius: 21px;
}

.Category .product-one:hover img.products {
  transform: scale(1.1);
}

.Category .product-one .overlay {
  border-radius: 20px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 20%;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000ab 83%, #000000 100%) 0% 0% no-repeat padding-box;
  transition: 0.5s ease-in-out;
  bottom: 0px;
  transition: all 350ms cubic-bezier(0, 0, 0.2, 1);
}

.Category .product-one:hover .overlay {
  border-radius: 20px;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000ab 83%, #000000 100%) 0% 0% no-repeat padding-box;
  height: 60%;
}

.Category .product-one {
  position: relative;
  margin-bottom: 5%;
}

.Category .product-one .content {
  left: 0px;
  width: 100%;
  bottom: 17px;
  position: absolute;
  right: 0px;
  justify-content: center;
}

.Category .product-one .content .outer-five-sub-heading {
  text-align: center;
}

.Category {
  margin-top: 4%;
  margin-bottom: 5%;
}

.Category h1.heading-Outer-five {
  margin-bottom: 3%;
}


.Category .swiper-wrapper {
  flex-direction: row;
}

.Category .swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-top: 0 !IMPORTANT;
}

.Category .swiper-wrapper {
  flex-direction: row !important;
}
.Category .swiper-pagination {
  margin-bottom: -2%;
}
/* .Category .swiper {
  padding-left: 40px !important;
} */
/********************* products two ***********************/

.Category .product-two .product-image img.products {
  width: 283px;
  height: 189px;
  border-radius: 10px;
  transition: transform 0.5s ease-in-out;
  /* object-fit: cover; */
  object-position: top;
}

.Category .product-two .overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 43px;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000ab 83%, #000000 100%) 0% 0% no-repeat padding-box;
  border-radius: 21px;
  bottom: 0px;
  transition: all 350ms cubic-bezier(0, 0, 0.2, 1);
  border-radius: 0px 0px 20px 20px;
}

.Category .product-two:hover .overlay {
  border-radius: 20px;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000ab 83%, #000000 100%) 0% 0% no-repeat padding-box;
  height: 60%;
}

.Category .product-two {
  margin-bottom: 7%;

  position: relative;
}

.Category .product-two .content {
  left: 0px;
  width: 100%;
  bottom: 17px;
  position: absolute;
  right: 0px;
  justify-content: center;
}

.Category .product-two .content .outer-five-sub-heading {
  text-align: center;
}

.Category .product-two:hover img.products {
  transform: scale(1.1);
}

.Category .product-two .product-image {
  overflow: hidden;
  border-radius: 21px;
}

/* Category Css End */




/* ***************************Media Query started****************************** */

@media (min-width: 0px) and (max-width: 485px) {
  .Category .product-one .product-image img.products {
    height: 200px;
  }

  .Category .product-one .overlay {
    height: 172px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {}

@media (min-width: 486px) and (max-width: 575px) {}

@media (min-width: 576px) and (max-width: 768px) {
  .Category .product-one .product-image img.products {
    height: 390px;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
.Category .product-two .product-image img.products {
    width: 256px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .Category .product-two .product-image img.products {
      width: 242px;
   
    }
}