
/* Sale Css Started */

section.Sale {
    margin-bottom: 5%;
    margin-top: 3%;
}
.Sale .Sale-main img.sale-img-one {
  height: 448px;
  width: 100%;
}
.Sale .Sale-main img.sale-img-two {
  width: 100%;
  height: 448px;
}

/* Sale Css End */



/* Media Query */

@media (min-width: 0px) and (max-width: 485px) {
  .Sale .Sale-main img.sale-img-one {
    height: 304px;
    margin-bottom: 3%;
  }
  .Sale .Sale-main img.sale-img-two {
    height: 304px;
  }
}
@media (min-width: 486px) and (max-width: 575px) {
  .Sale .Sale-main img.sale-img-one {
    height: 304px;
    margin-bottom: 3%;
  }
  .Sale .Sale-main img.sale-img-two {
    height: 304px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .Sale .Sale-main img.sale-img-one {
    height: 304px;
    margin-bottom: 3%;
  }
  .Sale .Sale-main img.sale-img-two {
    height: 304px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Sale .Sale-main img.sale-img-one {
    height: 262px;
  }
  .Sale .Sale-main img.sale-img-two {
    height: 262px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .Sale .Sale-main img.sale-img-one {
      height: 262px;
    }
    .Sale .Sale-main img.sale-img-two {
      height: 262px;
    }
  }
  