.MoreBrands .Brandscardsec .brandCard {
    border: 0;
    background: #F5F8FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 5px;
    height: 56px;
}

.MoreBrands .Brandscardsec {
    margin-bottom: 5%;
}

.MoreBrands .Brandscardsec .brandCard .LogoNamesec {
    display: flex;
}

.MoreBrands .Brandscardsec .brandCard .LogoNamesec .logoImg {
    width: 77px;
    height: 40px;
}

.MoreBrands .Brandscardsec .brandCard .card-body {
    padding: 8px 13px;
}

.MoreBrands .Brandscardsec .brandCard .LogoNamesec p {
    margin-top: auto;
    margin-bottom: auto !important;
    font-size: 18px;
    color: #000;
    margin-left: 19px;
}
.MoreBrands .Heading_Holder{
    text-align: left;
}