/* Home Banner Css Started */

.Home-Banner .inner-img {
  width: 100%;
  height: 600px;
  /* object-fit: cover; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Home-Banner .swiper {
  padding-bottom: 2%;
}

.Home-Banner .swiper .swiper-horizontal {
  margin-bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

/* Home Banner Css End */

/* Media  Query Started */
@media (min-width: 0px) and (max-width: 575px) {
  .Home-Banner .swiper {
    padding-bottom: 7%;
  }

  .Home-Banner .inner-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Home-Banner .swiper {
    padding-bottom: 5%;
  }
  .Home-Banner .inner-img {
    height: 345px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Home-Banner .swiper {
    padding-bottom: 5%;
  }
  .Home-Banner .inner-img {
    height: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .Home-Banner .swiper {
    padding-bottom: 5%;
  }
  .Home-Banner .inner-img {
    height: 345px;
  }
}
/* Media  Query End */
