.category-cards {
    margin-top: 3%;
    margin-bottom: 12%;
}

.category-cards .border-line {
    border-bottom: 1px solid #707070;
}

.category-cards .nav-pills .nav-link {
    background: transparent;
    color: #000;
    font-size: 13px;
}

.category-cards .nav-pills .nav-link.active {
    background: #ffe7d2;
    border-radius: 0%;
    color: #000;
    font-size: 13px;
    position: relative;
    border-radius: 5px;
}

.category-cards .scroller {
    overflow-y: scroll;
    height: 300px;
}

.category-cards .scroller::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.category-cards .scroller::-webkit-scrollbar-track {
    background: #e8e8e8;
}

.category-cards .view {
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

/* Handle */
.category-cards .scroller::-webkit-scrollbar-thumb {
    background: #ff8c28 0% 0% no-repeat padding-box;
    border-radius: 50px;
}
.category-cards .view-all {
    text-align: center;
}
/* .category-cards .nav-pills .nav-link.active .triangle-right {
    display: block !important;
    border-right: 3px solid #FF8C28;
    position: absolute;
    right: 0px;
    z-index: 99999;

}

.category-cards .nav-pills .nav-link .triangle-right {
    display: none;
    z-index: 99999;
} */



.subcategorytabss .subcat-tabcobt .silder-btn{
    position: relative;
    margin: 20px;
    }
    .subcategorytabss .subcat-tabcobt .silder-btn img{
      width: 30px;
    }
    .subcategorytabss .subcat-tabcobt .silder-btn .back-btn {
      position: absolute;
      left: 45%;
      bottom: 0px;
    }
    .subcategorytabss .subcat-tabcobt .silder-btn .next-btn {
      position: absolute;
      right: 45%;
      bottom: 0px;
    }
    
    .subcat-tabcobt {
      height: 100% !important;
    }
    
    .subcategorytabss .nav .nav-link {
    padding: auto !important;
    color: #000 ;
    font-size: 14px !important;
    padding: 8px  15px;
    }
    .subcategorytabss .bg-color{
        width: 90%;
    }
    .subcategorytabss .nav .nav-link.active {
      background-color: #ff8c28;
      color: #fff;
      padding: 8px  15px;
      font-size: 14px;
      font-weight: 500;
    }

    .subcategorytabss .swiper-slide {
        margin: 5px !important;
}


@media (min-width: 0px) and (max-width: 768px) {
    .category-cards .scroller {
        overflow-y: scroll;
        height: 178px;
    }
}

@media (min-width: 0px) and (max-width: 486px) {
    .category-cards {
        margin-bottom: 35%;
    }
}

@media (min-width: 486px) and (max-width: 767px) {
    .category-cards {
        margin-bottom: 30%;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .category-cards {
        margin-bottom: 25%;
    }
}
@media (min-width: 993px) and (max-width: 1199px) {
    .category-cards {
        margin-bottom: 25%;
    }
    .category-cards  .ProductCards .Card-Product {
        width: 100%;
        height: 425px;
    }
}