.label_text {
  font-size: 13px;
  font-weight: 600;
  color: #707070;
}

.heading {
  font-size: 25px;
  font-weight: 600;
  color: #333333;
}

.overlayText {
  font-size: 25px;
  font-weight: 400;
  color: #ffffff;
}

.overlaySubText {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
}

.subheading {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.spanText {
  font-size: 15px;
  font-weight: 500;
  color: #333333;
}

.subText {
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}

.subPrice {
  text-decoration-line: line-through;
}

@media (min-width: 0px) and (max-width: 320px) {
  .heading {
    font-size: 18px;
  }

  .subheading {
    font-size: 11px;
  }

  .spanText {
    font-size: 11px;
  }

  .subText {
    font-size: 10px;
  }

  .label_text {
    font-size: 11px;
  }

  .overlayText {
    font-size: 16px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .heading {
    font-size: 18px;
  }

  .subheading {
    font-size: 13px;
  }

  .spanText {
    font-size: 13px;
  }

  .subText {
    font-size: 10px;
  }

  .label_text {
    font-size: 13px;
  }

  .overlayText {
    font-size: 15px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .heading {
    font-size: 20px;
  }
  .subheading {
    font-size: 13px;
  }

  .spanText {
    font-size: 11px;
  }

  .subText {
    font-size: 10px;
  }

  .label_text {
    font-size: 11px;
  }

  .overlayText {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .heading {
    font-size: 20px;
  }
  .subheading {
    font-size: 13px;
  }

  .spanText {
    font-size: 11px;
  }

  .subText {
    font-size: 10px;
  }

  .label_text {
    font-size: 13px;
  }

  .overlayText {
    font-size: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .subheading {
    font-size: 13px;
  }

  .spanText {
    font-size: 11px;
  }

  .subText {
    font-size: 10px;
  }

  .label_text {
    font-size: 11px;
  }

  .overlayText {
    font-size: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .subheading {
    font-size: 13px;
  }

  .spanText {
    font-size: 11px;
  }

  .subText {
    font-size: 11px;
  }

  .label_text {
    font-size: 11px;
  }

  .overlayText {
    font-size: 20px;
  }
}
