.footer {
  background: #2c1d06 0% 0% no-repeat padding-box;
  padding-top: 79px;
  padding-bottom: 10px;
}

.footer .header-logo {
  width: 201px;
  height: 79px;
  margin: auto;
}

.footer .header-logo2 {
  width: 115px;
  height: 45px;

}

.footer .nav {
  margin-top: 23px;
  display: unset;
}

.footer .nav-link {
  color: #000;
  font-size: 15px;
  padding-left: 0%;
  /* margin: 0px 20px; */
}

.footer .nav-link:hover {
  color: #000;
  font-size: 15px;
  padding-left: 0%;
  /* margin: 0px 20px; */
}

.footer .nav-link.active {
  color: #000;
  font-size: 15px;
  padding-left: 0%;
  padding-left: 0%;
  /* margin: 0px 20px; */
}

.footer .nav-link:focus {
  color: #000;
  font-size: 15px;
  padding-left: 0%;
  /* margin: 0px 20px; */
}

.footer a {
  color: #000;
}

.footer .text-area-loaction {
  margin-top: 20px;
}

.footer p {
  font-size: 14px;
  color: #000;
}

.footer .email-box .form-control {
  border-radius: 0%;
  padding: 0px;
  border: none;
  font-size: 15px;
  color: #000;
  background-color: transparent;
  padding-left: 10px;
}

.footer .email-box .form-control::placeholder {
  font-size: 15px;
  color: #000;
}

.footer .email-box .form-control:focus {
  box-shadow: none;
}

.footer .email-box .input-group-text {
  border: none;
  background-color: transparent;
  padding-right: 0px;
}



.footer-bannermain {
  position: relative;
}



.footer .address-holder .text-holder {
  margin: 23px 0px;
}

.footer .address-holder .text-holder p {
  font-size: 13px;
  color: #fff;
  margin-bottom: 0%;
}


.footer .social-icon{
  display: flex;
}

.footer .social-icon .footer-soc-logo-holder {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
}

.footer .social-icon .sociallogo {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
}

.footer .address-holder {
  /* border-bottom: 1px solid #000; */
  padding-bottom: 20px;
}

.footer .copyright-holder p {
  color: #000;
  font-size: 13px;
  margin: 25px 0px;
}

.footer .copyright-holder {
  border-top: 1px solid #FFF;
}

.footer .location-holder {
  display: flex;
  justify-content: center;
  margin-top: 26px;
  margin-bottom: 20px;
}

.footer .call-holder span {
  color: #000;
  font-size: 13px;
}

.footer .call-holder .call-icon {
  color: #000;
}

.footer .text-holder h2 {
  font-size: 18px;
  color: #000;
}

@media(min-width:0px) and (max-width:1200px) {
  .footer .nav-link:hover {
    font-size: 13px;
  }

  .footer .nav-link.active {
    font-size: 13px;
  }

  .footer .nav-link {
    font-size: 13px;
  }

  .footer .nav-link:focus {
    font-size: 13px;
  }

  .footer .call-holder span {
    font-size: 12px;
  }

  .footer p {
    font-size: 13px;
  }
}

@media(min-width:0px) and (max-width:991px) {
  .footer .text-holder h2 {
    font-size: 15px;

  }

  .footer .text-holder {
    margin-top: 10px;
  }

  .footer .address-holder {
    padding-bottom: 0px;
  }
}

@media(min-width:0px) and (max-width:767px) {


  /* .footer .address-holder .social-icon {
    margin-top: 19px;
    margin-bottom: 16px;
  }

  .footer .location-holder {
    display: unset;
  }

  .footer .copyright-holder p {
    margin: 0px 0px;
  } */

  .footer .header-logo {
    width: 165px;
    height: 65px;
  }
}

/* subscribe-banner-css start*/
.footer-banner {
  margin-top: -170px;
}

.footer-bannermain {
  position: relative;
  margin-bottom: 38px;
}

.footer-bannermain .ban-contentmain {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.footer-bannermain .banconts {
  padding-top: 20px;
  padding-left: 70px;
  padding-right: 10px;
}

.footer-bannermain .banconts .newssetl {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.footer-bannermain .banconts .getss {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.footer .email-box .input-group {
  padding: 0px 5px;
  background-color: #fff;
  border-radius: 25px;
}

.footer-bannermain .subscribe-btn {
  border-radius: 21px;
  /* height: 38px; */
}

.footer-bannermain .footerimg {
  width: 100%;
  height: 202px;
  border-radius: 10px;
}

@media (min-width:0px) and (max-width:485px) {
  .footer-bannermain input.form-control {
    margin: 0px 5px;
  }

  .footer .email-box .form-control {
    padding: 0px;
    padding-left: 0px;
  }

  .footer-bannermain .banconts {
    padding-top: 15px;
    padding-left: 10px;
  }

  .footer-bannermain .banconts .newssetl {
    font-size: 16px;

  }

  .footer .email-box .form-control::placeholder {
    font-size: 12px;
  }

  .subscribe-btn {
    font-size: 11px !important;
    padding: 6px 8px !important;
  }
}

/* @media (min-width: 0px) and (max-width: 991px) {
  .footer-bannermain .banconts {
    padding-top: 15px;
    padding-left: 10px;
  }

  .footer-bannermain .banconts .newssetl {
    font-size: 16px;

  }
  .footer .email-box .form-control::placeholder {
      font-size: 12px;
}
} */