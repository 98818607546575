/* Card Product Css Started */

.ProductCards .Card-Product {
  height: 394px;
  padding: 11px 12px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  position: relative;
  border: 1px solid white;
}

.ProductCards .Card-Product:hover {
  border: 1px solid orange;
}

.ProductCards .Card-Product .demo {
  height: 239px;
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 1/1;
  /* object-fit: cover; */
}

.ProductCards .Card-Product .cate-demo {
  height: 239px;
  width: 100%;
  /* width: 100%; */
  border-radius: 10px;
  aspect-ratio: 1 / 1;
}
.ProductCards .Card-Product .image-card {
  text-align: center;
}

.ProductCards .Card-Product button.View {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 10px;
  padding: 4px 20px;
  margin: auto;
}

.ProductCards .Card-Product .view-details {
  margin-top: 11%;
  text-align: center;
}

.ProductCards .Card-Product .card-content {
  padding-top: 10px;
}

.Portfolio-Cards .card-portfolio .content .like-btn .circle {
  width: 34px;
  height: 34px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.ProductCards .btn-style511 {
  --btn-color: transparent;
  --btn-hover-color: #1d44ad;
  position: relative;
  border: none;
  background-color: var(--btn-color);
  color: #484848;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.25s ease;
}

.ProductCards .btn-style511 span {
  position: relative;
  z-index: 2;
}

.ProductCards .btn-style511::before,
.btn-style511::after {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #1d44ad;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}

.ProductCards .btn-style511::before {
  top: 0;
  transform: translateX(-100%);
}

.ProductCards .btn-style511::after {
  bottom: 0;
  transform: translateX(100%);
}

.ProductCards .Card-Product:hover .btn-style511::after {
  bottom: 0;
  transform: translateX(0%);
}

.ProductCards .Card-Product:hover .btn-style511 {
  color: #fff;
}

.ProductCards .Card-Product:hover .btn-style511::before,
.btn-style511:hover::after {
  transform: translateX(0);
}

.ProductCards .click-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.35s;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 15px;
  padding: 4px 20px;
  margin: auto;
  position: absolute;
  bottom: 11px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.ProductCards .click-btn span {
  position: relative;
  z-index: 2;
}

.ProductCards:hover .click-btn::before {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background: #1d44ad 0% 0% no-repeat padding-box;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}

.ProductCards:hover .click-btn::after {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background: #1d44ad 0% 0% no-repeat padding-box;
  transition: transform 0.25s ease;
  content: "";
  z-index: 1;
}

.ProductCards:hover .click-btn::before {
  top: 0;
  transform: translateX(-100%);
}

.ProductCards .click-btn::after {
  bottom: 0;
  transform: translateX(100%);
}

.ProductCards .click-btn:hover {
  color: #fff;
}

.Card-Product:hover .ProductCards .click-btn:hover::before,
.click-btn:hover::after {
  transform: translateX(0);
}

@media (min-width: 0px) and (max-width: 485px) {
  .ProductCards .Card-Product {
    width: 100%;
  }

  .ProductCards .Card-Product img.Demo {
    width: 100%;
  }

  .ProductCards .click-btn {

    font-size: 13px;
    padding: 4px 15px;

  }
  .ProductCards .text-outer-five {
    text-align: center;
}
}

@media (min-width: 486px) and (max-width: 991px) {
  .ProductCards .click-btn {
    font-size: 12px;
    padding: 4px 10px;
  }
  .ProductCards .text-outer-five {
    text-align: center;
}
}

@media (min-width: 486px) and (max-width: 1199px) {
  .ProductCards .Card-Product {
    width: 100%;
  }

  .ProductCards .Card-Product img.Demo {
    width: 100%;
  }

}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ProductCards .click-btn {
    font-size: 13px;
    padding: 4px 14px;
  }
}