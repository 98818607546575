.faq {
    margin-bottom: 12%;
}

.faq .faq-banner .faq-img {
    width: 100%;
    height: 405px;
    border-radius: 10px;
}

.faq .faq-holder {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 0.95;
    padding: 15px 20px 37px 25px;
    margin-top: -144px;
}

.faq .faq-holder h1 {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.faq .faq-holder h2 {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.faq .faq-holder p {
    font-size: 14px;
}

.faq .registration-box {
    background: #ff8c28;
    border-radius: 10px 10px 0px 0px;
    opacity: 0.95;
    padding: 10px;
}

.faq .registration-box h2 {
    font-size: 18px;
    font-weight: 500;
    color: #FFF;
}

.faq .accordion {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 10px 10px;
    opacity: 0.95;
}

.faq .accordion-item:first-of-type>.accordion-header .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.faq .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.faq .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
    box-shadow: none;
    font-size: 15px;
    font-weight: 500;
}

.faq .accordion-button {
    color: #000;
    background-color: transparent;
    box-shadow: none;
    font-size: 15px;
    font-weight: 500;
}

.faq .accordion-button:focus {
    box-shadow: none;
}

.faq .accordion-button::after {
    /* content: ""; */
    background-image: none;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    font-size: 20px;
    width: unset;
    height: unset;
}

.faq .accordion-button:not(.collapsed)::after {
    background-image: none;
    width: unset;
    height: unset;
}

.faq .accordion-body p {
    font-size: 14px;
}

@media(min-width:768px) and (max-width:991px) {
    .faq .faq-holder h1 {
        font-size: 20px;
    }

    .faq .faq-banner .faq-img {
        height: 295px;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .faq .faq-holder h1 {
        font-size: 18px;
    }

    .faq .faq-banner .faq-img {
        height: 236px;
    }

    .faq .registration-box h2 {
        font-size: 16px;
    }

    .faq .faq-holder p {
        font-size: 13px;
    }

    .faq .accordion-body p {
        font-size: 13px;
    }

    .faq .accordion-button {
        font-size: 14px;
    }

    .faq .accordion-button:not(.collapsed) {
        font-size: 14px;
    }

    .faq .faq-holder {
        margin-top: -94px;
    }
}

@media(min-width:0px) and (max-width:321px) {
    .faq {
        margin-bottom: 38%;
    }
}

@media(min-width:321px) and (max-width:486px) {
    .faq {
        margin-bottom: 35%;
    }
}

@media(min-width:486px) and (max-width:567px) {
    .faq {
        margin-bottom: 30%;
    }
}

@media(min-width:568px) and (max-width:767px) {
    .faq {
        margin-bottom: 25%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .faq {
        margin-bottom: 20%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .faq {
        margin-bottom: 15%;
    }
}