.header-section {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  position: sticky;
  top: 0px;
  z-index: 999;
}

.header-section .second-header {
  background: #E9E9E9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  height: 62px;
}

.header-section .header-main .header-img .header-logo {
  width: 100%;
  height: 61px;
  background-color: transparent;
  object-fit: contain;
}

.header-section .header-main .contact-btn {
  background: #FF8C28 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 15px;
  color: #FFF;
  font-weight: 500;
  border: none;
  width: 114px;
  height: 38px;
}

.header-section .header-main .search-icon-area {
  background: #FFE5CF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
}


.header-section .header-main .search-bar .search-bar-field {
  z-index: 0;
}


.header-section .header-main .search-bar .form-control {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 0.5px solid #707070;
  font-size: 13px;

}

.header-section .header-main .search-bar .form-control:focus {
  box-shadow: none;
}

.header-section .header-main .search-bar .btn-primary {
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #808080;
  text-align: left;
}

.header-section .header-main .search-bar .drop-all-category {
  border: none;
  position: relative;
  display: block;
}

.header-section .header-main .search-bar .dropdown-toggle {
  width: 202px;
}

.header-section .header-main .search-bar .image-category {
  right: -17px;
  position: absolute;
  top: 4px;
  z-index: 999;
}

.header-section .header-main .search-bar .drop-img {
  width: 12px;
  height: 12px;
}

.header-section .header-main .search-bar .dropdown-toggle::after {
  content: "";
  border: none;
}

.header-section .header-main .search-bar .dropdown-menu {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #FFF;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  border-radius: 0%;
  overflow-y: scroll;
  height: 200px;
  width: 213px;
}

.header-section .header-main .search-bar .dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.header-section .header-main .search-bar .dropdown-menu::-webkit-scrollbar-track {
  background: #E8E8E8;
}

/* Handle */
.header-section .header-main .search-bar .dropdown-menu::-webkit-scrollbar-thumb {
  background: #FF8C28 0% 0% no-repeat padding-box;
  border-radius: 50px;
}

.header-section .header-main .search-bar .dropdown-item {
  font-size: 13px;
  margin-bottom: 5px;
}

.header-section .header-main .search-bar .dropdown-item:hover {
  background: #FFE7D2;
  font-size: 13px;
  margin-bottom: 5px;
}

.header-section span.navbar-toggler-icon {
  font-size: 15px;
}

.header-section .navbar-toggler:focus {
  box-shadow: none;
}

.header-section .second-header .navbMainOne {
  margin-left: 24px;
}

.header-section .second-header .nav-link {
  font-size: 15px;
  font-weight: 600;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
  color: #000;
}

.header-section .second-header .nav-link-with-borders {
  position: relative;
}

.header-section .second-header .nav-link-with-borders.active {
  color: #000;
  /* Change color for active link */
}

.header-section .second-header .nav-link-with-borders.active .blue-border {
  background-color: blue;
  /* Change color for blue border */
}

.header-section .second-header .nav-link-with-borders.active .orange-border {
  background-color: orange;
  /* Change color for orange border */
}

.header-section .second-header .blue-border {
  position: absolute;
  bottom: -5px;
  height: 3px;
  width: 30%;
  transition: all 0.3s ease-in-out;
}

.header-section .second-header .orange-border {
  position: absolute;
  bottom: -5px;
  height: 3px;
  width: 70%;
  transition: all 0.3s ease-in-out;
}

.header-section .second-header .blue-border {
  left: 0;
}

.header-section .second-header .orange-border {
  right: 0;
}

.header-section .second-header .navbar-nav .nav-link.active {
  display: block;
}

.header-section .second-header .cart-logo {
  color: #000;
  margin: 10px 0px 0px 26px;
  cursor: pointer;
}

.header-section .second-header .login {
  font-size: 18px;
  color: #000;
  padding-left: 16px;
  margin-top: 6px;
}

.header-section .navbar-toggler {
  margin-right: 15px !important;
  border: none;
}

.header-section .mobile-view-dropdown {
  display: none;
}

.header-section .button-holder-mobile-view {
  display: none;
}



@media(min-width:0px) and (max-width:992px) {
  .header-section .second-header {
    height: auto;
    /* padding-bottom: 20px; */
  }

  .header-section .second-header .navbMainOne {
    margin-left: 0px;
  }

  .header-section .second-header .cart-logo {
    margin: 0px 10px;
  }

  .header-section .second-header .all-icon {
    padding: 15px 0px;
  }

  .header-section .header-main .search-bar .drop-all-category {
    display: none;
  }

  .header-section .mobile-view-dropdown {
    display: block;

  }

  .header-section .mobile-view-dropdown .dropdown-toggle {
    width: 202px;
  }

  .header-section .mobile-view-dropdown .image-category {
    right: 0px;
    position: absolute;
    top: 4px;
    z-index: 999;
  }

  .header-section .mobile-view-dropdown .drop-img {
    width: 12px;
    height: 12px;
  }

  .header-section .mobile-view-dropdown .dropdown {
    margin-bottom: 10px;
  }

  .header-section .mobile-view-dropdown .dropdown-toggle {
    position: relative;
  }

  .header-section .mobile-view-dropdown .dropdown-toggle::after {
    content: "";
    border: none;
  }

  .header-section .mobile-view-dropdown .dropdown-menu {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #FFF;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    border-radius: 0%;
    overflow-y: scroll;
    height: 200px;

  }

  .header-section .mobile-view-dropdown .btn-primary {
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: #000;
    text-align: left;
    font-weight: 600;
    margin-top: 10px;
  }

  .header-section .mobile-view-dropdown .btn-primary:focus {
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: #000;
    text-align: left;
    font-weight: 600;
  }

  .header-section .mobile-view-dropdown .dropdown-menu::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .header-section .mobile-view-dropdown .dropdown-menu::-webkit-scrollbar-track {
    background: #E8E8E8;
  }

  /* Handle */
  .header-section .mobile-view-dropdown .dropdown-menu::-webkit-scrollbar-thumb {
    background: #FF8C28 0% 0% no-repeat padding-box;
    border-radius: 50px;
  }

  .header-section .mobile-view-dropdown .dropdown-item {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .header-section .mobile-view-dropdown .dropdown-item:hover {
    background: #FFE7D2;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .header-section .header-main .contact-btn {
    font-size: 13px;
    width: 114px;
    height: 32px;

  }

  .header-section .second-header .nav-link-with-borders.active .blue-border {
    background-color: blue;
    display: none;
  }

  .header-section .second-header .nav-link-with-borders.active .orange-border {
    background-color: orange;
    display: none;
  }

  .header-section .second-header .nav-link {
    font-size: 13px;
  }
}

@media(min-width:0px) and (max-width:767px) {

  .header-section .button-holder-mobile-view {
    display: block;
  }

  .header-section .button-holder-mobile-view .contact-btn {
    background: #FF8C28 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    color: #FFF;
    font-weight: 500;
    border: none;
    width: 97px;
    height: 35px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 26px;
  }

  .header-section .header-main .button-holder {
    display: none;
  }
}