.Grand_Total_Table .procheckout_btn {
  background: #1d44ad 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 1px solid #1d44ad;
  font-size: 16px;
  font-weight: 100;
  color: #ffffff;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
}

.Grand_Total_Table .Heading_text_Holder {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Grand_Total_Table .Heading_text_Holder:after {
    content: "";
    position: absolute;
    width: 24%;
    top: 50%;
    right: 0;
    margin-left: 10px;
    border-top: 2px solid #ccc;
}
