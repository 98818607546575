/* Wishlist Css Started */

.Wishlist .Wishlist-Banner img.wishlist-banner {
    height: 100%;
    width: 100%;
}
.Wishlist .Wishlist-Cards {
    margin-bottom: 10%;
}
.Wishlist  {
    margin-bottom: 8%;
}
.Wishlist .view-all  p.view {
    font-size: 14px;
    text-decoration: underline;
}
/* Wishlist Css End */


/* Media Query Started */
@media (min-width:0px) and (max-width:320px) {
    .Wishlist {
        margin-bottom: 37%;
    }
    .view-all {
        text-align: center;
    }
}
@media (min-width:321px) and (max-width:485px) {
    .Wishlist {
        margin-bottom: 29%;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .Wishlist {
        margin-bottom: 23%;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .Wishlist {
    margin-bottom: 16%;
}
}
@media (min-width:768px) and (max-width:991px) {
    .Wishlist {
    margin-bottom: 15%;
}
}

/* Media Query End */