.product-information .manufacture-bar p {
    font-size: 10px;
    margin-bottom: 0%;
}
section.first-vendor {
    margin-top: 3%;
}
.product-information .manufacture-bar .progress {
    height: 6px;
}

.product-information .manufacture-bar .progress-bar {
    border-radius: 3px;
}

.product-information .manufacture-bar .exclamation-icon {
    color: #ccc;
    cursor: pointer;
}

.product-information .manufacture-bar .show-percentage {
    position: relative;
}

.product-information .manufacture-bar .show-percentage:hover .used-capacity {
    display: block;
    position: absolute;

}

.product-information .manufacture-bar .show-percentage .used-capacity {
    display: none;

}

.product-information .manufacture-bar .show-percentage .used-capacity p {
    font-size: 10px;
    width: 143px;
}

.product-information .manufacture-bar .show-percentage .used-capacity .red-circle {
    width: 5px;
    height: 5px;
    background: #840304;
    border-radius: 50px;
    margin-top: 4px;
}

.product-information .manufacture-bar .show-percentage .used-capacity .green-circle {
    width: 5px;
    height: 5px;
    background: #8CD676;
    border-radius: 50px;
    margin-top: 4px;
}

.product-information .manufacture-bar .show-percentage .used-capacity .yellow-circle {
    width: 5px;
    height: 5px;
    background: #F88D28;
    border-radius: 50px;
    margin-top: 4px;
}

.product-information .Warranty {
    margin: 0px 10px;
    width: 38px;
    height: 38px;
    background-color: #fff4e9;
    border-radius: 100px;
    padding: 6px 10px;
}

.product-information .year {
    text-align: center;
    width: 60px;
    font-size: 10px;
}

.product-information .Warranty img.icon {
    width: 18px;
    height: 20px;
}

.product-information .accordion-body {
    padding-left: 0%;
    padding-right: 0%;
}

.product-information .accordion-button {
    padding-left: 0%;
    padding-right: 0%;
}

.product-information .accordion-button:focus {
    box-shadow: none;
}

.brand-holder span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-left: 10px;
}

.product-information .accordion-button:not(.collapsed) {
    color: #000000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding-left: 0%;
    padding-right: 0%;
}

.product-information button.accordion-button {
    font-size: 16px;
    font-weight: 600;
}

.product-information button.accordion-button {
    font-size: 16px;
    font-weight: 600;
}

.product-information .product {
    color: #6479B5;
    font-size: 12px;
    font-weight: 500;
}

.product-information .product-text {
    color: #000000;
    font-size: 12px;
}

.product-information .accordion-item {
    border-radius: 0px !important;
    border-top: 1px solid rgb(199, 199, 199);
    border-bottom: 1px solid rgb(212, 212, 212);
    border-left: none;
    border-right: none;
}
.product-information {
    margin-top: 5%;
  }
  .product-information .phone-color {
    padding: 4px 4px;
    margin-left: 8px;
    width: 50px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #FF8C28;
}
.product-information .images-cards{
    display: flex;
}
.product-information .phone-color img.icon {
  
    width: 41px;
    height: 41px;
}
@media (min-width: 0px) and (max-width: 420px) {
    .product-information .manufacture-bar .show-percentage:hover .used-capacity {
        left: -59px !important;
    }
}

@media (min-width: 0px) and (max-width: 485px) {
    .B2b-Seller-Detail .manufacture-bar .show-percentage:hover .used-capacity {
        left: -27px;
    }
}