.home-swiper .desktop-view-slider .swiper {
  right: 5%;
  margin-top: 2%;
}

.home-swiper {
  /* margin: 5% 0%; */
  padding: 3% 0%;
  background: #F0F4FF 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-bottom: 10%;
}

.home-swiper .desktop-view-slider .swiper-slide-prev .about-img {
  width: 100% !important;
  height: 69px !important;
  border-radius: 5px;
  margin-left: 0%;
}

.home-swiper .desktop-view-slider .swiper-slide .about-img {
  border-radius: 5px;
  margin-left: 0%;
  width: 100%;
  height: 74px;
}
.home-swiper .about-img{
  width: 100%;
}
.home-swiper .Desktop-slider .swiper-slide-next .about-img {
  width: 100%;
  height: 69px;
  border-radius: 5px;
  margin-left: 0%;
}

.home-swiper .desktop-view-slider .swiper-slide-active .about-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-left: 0%;
  width: 174px;
  height: 175px;
}

.home-swiper .main-img-div {
  border: 4px solid #FF8C28;
  border-radius: 10px;
  position: relative;
}

.home-swiper .desktop-view-slider .swiper-slide-active .main-img-div {
  border: 6px solid #FF8C28;
  border-radius: 20px;
  margin-left: -32%;
  border: none;
}

.home-swiper .desktop-view-slider .swiper-slide-active .main-img-div .left-active-border {
  border: 4px solid #FF8C28;
  position: absolute;
  height: 104%;
  top: -4px;
  left: 0px;
  border-radius: 20px 0px 0px 21px;
}

.home-swiper .desktop-view-slider .swiper-slide-active .main-img-div .top-active-border {
  background-color: #FF8C28;
  height: 6px;
  width: 52px;
  position: absolute;
  border-radius: 20px 0px 0 0;
  top: -6px;
}

.home-swiper .desktop-view-slider .swiper-slide-active .main-img-div .bottom-active-border {
  background-color: #FF8C28;
  height: 6px;
  width: 52px;
  position: absolute;
  bottom: -4px;
  border-radius: 0 0 0px 0px;
  left: 5px;
}



.home-swiper .main-card h1 {
  margin-top: 35px;
  font-size: 18px;
  color: black;
  font-weight: 700;
}

.home-swiper .desktop-view-slider .swiper-slide-active .main-card {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 60px 15px 60px 0px;
  margin: 15px;
  /* height: 299px; */
}


.home-swiper .main-card h1,
.home-swiper .main-card p {
  display: none;
}


.home-swiper .desktop-view-slider .swiper-slide-active .main-card p {
  display: block;
  font-size: 14px;
  color: #000000;
  font-family: "Poppins" !important;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
}

.home-swiper .desktop-view-slider .swiper-slide-active .main-card h1 {
  display: block;
  font-size: 14px;
  color: #000000;
  font-family: "Poppins" !important;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}


.home-swiper .desktop-view-slider .swiper-slide-active {
  width: 580px !important;
  right: 14%;
  transform: translate3d(-16px, 0px, -815.51px) rotateX(0deg) rotateY(0deg) scale(1.6) !important;
}


.home-swiper .desktop-view-slider .swiper-slide.swiper-slide-prev {
  transform: translate3d(5px, 0px, -460.637px) rotateX(0deg) rotateY(0deg) scale(2.1) !important;
}


.home-swiper .desktop-view-slider .swiper-slide.swiper-slide-next {
  transform: translate3d(55px, 0px, -460.637px) rotateX(0deg) rotateY(0deg) scale(2.1) !important;
}

.home-swiper .desktop-view-slider .swiper-slide {
  margin-left: 2%;
  transform: translate3d(-16px, 5px, -815.51px) rotateX(0deg) rotateY(0deg) scale(1.6) !important;
}


.home-swiper .desktop-view-slider .main-card {
  margin-top: 37%;
}

.home-swiper .desktop-view-slider .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}

.home-swiper .desktop-view-slider .heading-Outer-five {
  color: black;

}

.home-swiper .desktop-view-slider .content {
  padding: 25px 0px;
}

.home-swiper .mobile-view-slider {
  display: none;
}

@media screen and (min-width: 0px) and (max-width:767px) {
  .home-swiper .desktop-view-slider {
      display: none;
  }

  .home-swiper .mobile-view-slider {
      display: block;
  }

  .home-swiper .mobile-view-slider .card {
      border: none;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
  }

  .home-swiper .mobile-view-slider .main-card1 .about-img1 {
      width: 200px;
      height: 200px;
      border: 4px solid #FF8C28;
      border-radius: 20px;

  }

  .home-swiper .mobile-view-slider .content1 .text-outer-five1 {
      font-size: 12px;
  }

  .home-swiper .mobile-view-slider .content1 .text-outer-five2 {
      font-size: 14px;
      font-weight: 600;
  }

  .home-swiper .mobile-view-slider .swiper-pagination-bullet-active {
      background: #ff8c28;
      border-radius: 5px;
  }

  .home-swiper {
      margin-bottom: 30%;
  }
}



@media screen and (min-width: 768px) and (max-width:769px) {
  .home-swiper .main-card p {
      font-size: 12px;
  }

  .home-swiper .main-card h1 {
      font-size: 16px;
  }

  .home-swiper .swiper {
      right: 11%;
  }

  .home-swiper .swiper-slide.swiper-slide-prev {
      transform: translate3d(-56px, 0px, -27.3649px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }

  .home-swiper .swiper-slide.swiper-slide-next {
      transform: translate3d(0px, 0px, -27.3649px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }

  .home-swiper .main-card {
      margin-top: 5%;
  }

  .home-swiper .swiper-slide-active .main-card {
      margin: 28px 13px 28px 103px;
      padding: 40px 15px 40px 0px;
  }

  .home-swiper .main-card {
      margin: 28px 7px 28px 103px;
  }

  .home-swiper .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active {
      width: 541px !important;
      /* right: 24%; */
      transform: translate3d(-16px, 11px, -815.51px) rotateX(0deg) rotateY(0deg) scale(1.6) !important;
  }

  .home-swiper .swiper-slide-active .main-img-div .top-active-border {
      width: 35px;
  }

  .home-swiper .swiper-slide-active .main-img-div .bottom-active-border {
      width: 35px;
  }

  .home-swiper {

      margin-bottom: 20%;
  }

  .home-swiper .content {
      margin-top: 24px;
      padding: 0px 0px;
      margin-left: 25px;

  }
}

@media screen and (min-width: 769px) and (max-width:991px) {

  .home-swiper .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active {
      width: 424px !important;
      right: 11%;
  }

  .home-swiper .swiper-slide.swiper-slide-next {
      transform: translate3d(-13px, -34px, -460.637px) rotateX(0deg) rotateY(0deg) scale(1.4) !important;
  }

  .home-swiper .swiper-slide.swiper-slide-prev {
      transform: translate3d(87px, -26px, -460.637px) rotateX(0deg) rotateY(0deg) scale(1.4) !important;
  }

  .home-swiper .swiper-slide-active .main-card {
      padding: 45px 15px 45px 0px;
  }

  .home-swiper .swiper-slide-active .main-img-div .top-active-border {
      width: 34px;
  }

  .home-swiper .swiper-slide-active .main-img-div .bottom-active-border {
      width: 29px;
  }
  .home-swiper .desktop-view-slider .swiper-slide {
    margin-left: 8%;
}
.home-swiper .desktop-view-slider .main-card {
  opacity: 0;
}
}

@media screen and (min-width: 992px) and (max-width:1200px) {
  .home-swiper .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active {
      right: 9%;
      width: 527px !important;
  }

  .home-swiper .swiper-slide.swiper-slide-next {
      transform: translate3d(50px, -18px, -460.637px) rotateX(0deg) rotateY(0deg) scale(2.1) !important;
  }

  .home-swiper .swiper-slide.swiper-slide-prev {
      transform: translate3d(66px, -18px, -460.637px) rotateX(0deg) rotateY(0deg) scale(2.1) !important;
  }

  .home-swiper .swiper-slide-active .main-img-div .top-active-border {
      width: 44px;
  }

  .home-swiper .swiper-slide-active .main-img-div .bottom-active-border {
      width: 44px;
  }

  .home-swiper .swiper {
      right: 0%;
  }
  .home-swiper .desktop-view-slider .main-card {
    margin-top: 52%;
}
}


@media screen and (min-width: 1201px) and (max-width:1400px) {

  /* .home-swiper .swiper-slide.swiper-sliden-next {
      transform: translate3d(-25px, 28px, -460.637px) rotateX(0deg) rotateY(0deg) scale(2.1) !important;
  } */
  .home-swiper .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active {
      width: 531px !important;
      right: 10%;
  }

  .home-swiper .swiper-slide-active .main-card {
      padding: 40px 15px 40px 0px;
  }

  .home-swiper .swiper-slide-active .main-img-div .top-active-border {
      width: 46px;
  }

  .home-swiper .swiper-slide-active .main-img-div .bottom-active-border {
      width: 46px;
  }
}