.Banner_Card .overlay {
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 10px 10px;
  opacity: 0.7;
  width: 100%;
  padding: 20px 0px;
  text-align: center;
}

.Banner_Card .Heart_Like {
  position: absolute;
  top: 5px;
  right: 5px;
}

.Banner_Card .Product_Details {
  height: 140px;
  overflow: hidden;
  overflow-y: auto;
}

.Banner_Card .Product_Details::-webkit-scrollbar {
  width: 5px;
}
.Banner_Card .Product_Details::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.Banner_Card .Product_Details::-webkit-scrollbar-track {
  background: #E8E8E8;
}
.Banner_Card .Product_Details::-webkit-scrollbar-track:horizontal {
  background: #E8E8E8;
}
/* Handle */
.Banner_Card .Product_Details::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
 .Banner_Card .Product_Details::-webkit-scrollbar-thumb:horizontal{
   background: #c7c7c7;
  border-radius: 5px;
   }


@media (min-width: 0px) and (max-width: 768px) {
  /* .Banner_Card .img-holder {
    margin-bottom: 15px;
  } */

  /* .Banner_Card .overlay {
    bottom: 15px;
  } */
}




@media (min-width: 0px) and (max-width: 320px) {
  .Banner_Card .overlay {
    padding: 1px 0px !important;
}
}

@media (min-width: 321px) and (max-width: 485px) {
  .Banner_Card .overlay {
    padding: 1px 0px !important;
}
}