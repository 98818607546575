.hearticn {
  width: 27px;
  height: 26px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #817d7d29;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 4px 4px;
  margin-left: auto;
  /* padding: 13px 9px; */
  cursor: pointer;
}
  
  .hearticn .gobiheart {
    font-size: 16px;
    color: #b4b2b2;
  }

  .hearticn .gobiheartss{
    font-size: 16px;
    color: #df4223;
  }

