.Product_Swiper .Tab_Slider_Img {
  height: 349px;
  width: 100%;
  border-radius: 10px;
}

@media (min-width: 0px) and (max-width: 321px) {
  .Product_Swiper .Tab_Slider_Img {
    height: 150px;
}

  .Product_Details_Div .color_cart_holder .Cart_Holder .CartClassName {
    font-size: 13px;
  }
}

@media (min-width: 320px) and (max-width: 485px) {
  .Product_Swiper .Tab_Slider_Img {
    height: 200px;
  }

  .Product_Details_Div .color_cart_holder .Cart_Holder .CartClassName {
    font-size: 13px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Product_Swiper .Tab_Slider_Img {
    height: 300px;
  }

  .Product_Details_Div .color_cart_holder .Cart_Holder .CartClassName {
    font-size: 13px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Product_Swiper .Tab_Slider_Img {
    height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Product_Swiper .Tab_Slider_Img {
    height: 235px;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Product_Swiper .Tab_Slider_Img {
    height: 230px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Product_Swiper .Tab_Slider_Img {
    height: 230px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .Product_Swiper .Tab_Slider_Img {
    height: 270px;
  }
}
