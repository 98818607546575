.category-banner .image-banner .category-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(min-width:0px) and (max-width:992px) {
    .category-banner .image-banner .category-img {
        width: 100%;
        height: auto;
    }
}