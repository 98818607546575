.Reviews {
    margin-top: 5%;
}

.Reviews .RatingmainRow .ratingStarsec {
    display: flex;
    margin-bottom: 5%;

}

.Reviews .RatingmainRow .ratingStarsec p {
    margin-left: 30px;
    font-size: 15px;
    color: #323232;
    margin-top: auto;
}

.Reviews .RatingmainRow .whatYoulikesec h3 {
    font-size: 14px;
    color: #323232;
}

.Reviews .RatingmainRow .textareaRating {
    width: 100%;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    padding: 15px;
    border: 0;
}

.Reviews .RatingmainRow .ratingbtnsec {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5%;
}

.Reviews .RatingmainRow .ratingbtnsec .cancelbtn {
    background: #B7B7B7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 0;
    margin-right: 15px;
    padding: 5px 45px;
    color: #fff;
}

.Reviews .RatingmainRow .ratingbtnsec .postbtn {
    background: #E5ECFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 0;
    padding: 5px 45px;
}

.Reviews .RatingmainRow .ReviewsContentsec h3 {
    font-size: 15px;
    color: #333333;
    font-weight: 600;
}

.Reviews .RatingmainRow .ReviewsContentsec .specificReviews {
    margin-bottom: 20px;
}

.Reviews .RatingmainRow .ReviewsContentsec .specificReviews h3 {
    font-size: 16px;
    font-weight: normal;
}

.Reviews .RatingmainRow .ReviewsContentsec .specificReviews p {
    font-size: 13px;
}

.Reviews .RatingmainRow .ReviewsContentsec .ratingDatesec {
    display: flex;
}

.Reviews .RatingmainRow .ReviewsContentsec .ratingDatesec p {
    margin-top: auto;
    margin-left: 15px;
}


@media screen and (min-width: 0px) and (max-width: 767.97px) {
    .Reviews .RatingmainRow .ratingbtnsec {
        justify-content: center;
    }
}