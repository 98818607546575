.happy-clients {
    margin: 7% 0% 6% 0%;
}

.happy-clients .client-div {
    padding: 20px;
    background: #FFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 34px #00000012;
    border-radius: 5px;
    height: 80%;
    min-height: 201px;
}

.happy-clients .client-div .client-img {
    width: 79px;
    height: 76px;
    box-shadow: 0px 10px 32px #0000000F;
    border-radius: 38px;
}

.happy-clients .client-div .client-name {
    font-size: 23px;
    font-weight: 600;
    margin-top: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
}

.happy-clients .client-div .client-review {
    font-size: 14px;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.happy-clients .client-div .star-icon {
    width: 14px;
    margin-right: 5px;
    color: #FFBB00;
}

/* .happy-clients .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #312E2E;
}

.happy-clients .swiper-pagination-bullet {
    background: #888888;
    opacity: 1;
} */

.happy-clients .silder-btn {
    position: relative;
}

.happy-clients .silder-btn .back-btn {
    content: url("../../../../public/assets/Homepage/Popular-Categories/left.png");
    width: 20px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 141px;
    /* z-index: 999; */
    left: -11px;
    z-index: 1;
}

.happy-clients .silder-btn .next-btn {
    content: url("../../../../public/assets/Homepage/Popular-Categories/right.png");
    width: 20px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 141px;
    z-index: 999;
    right: -11px;
    z-index: 1;
}

@media(min-width:0px) and (max-width:767px) {
    .happy-clients .client-div .client-img {
        width: 73px;
        height: 72px;
    }

    .happy-clients .client-div .client-name {
        font-size: 16px;
        margin-top: 23px;
    }

    .happy-clients .client-div .client-review {
        font-size: 12px;
    }

    .happy-clients .silder-btn .back-btn {
        left: -8px;
    }

    .happy-clients .silder-btn .next-btn {
        right: -6px;
    }
}